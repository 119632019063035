<template>
  <section fluid id="comments">
    <b-row>
      <b-col cols="10">
        <b-form-group>
          <TextArea
            id="comment-text-area"
            placeholder="Descrever anotação"
            :value="comment"
            @input="comment = $event"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-button
          v-if="editIndex !== null"
          :disabled="!comment || isSaving"
          variant="primary"
          class="w-100"
          @click="updateComment"
        >
          <b-spinner v-if="isSaving" small variant="light" />
          <span v-else>Alterar</span>
        </b-button>
        <b-button
          v-else
          :disabled="!comment || isSaving"
          variant="primary"
          size="lg"
          class="w-100"
          @click="addComment"
        >
          <b-spinner v-if="isSaving" small variant="light" />
          <span v-else>Adicionar</span>
        </b-button>
      </b-col>
    </b-row>

    <CommentList
      :surgeryInformationId="surgeryInformationId"
      :commentsProps="comments"
      @edit="edit"
      @update-comments="updateComments"
    />
  </section>
</template>

<script>
import { getCurrentUser, getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Comments',
  props: {
    patientId: {
      type: String,
      required: true
    },
    surgeryInformationId: String,
    showSaveButton: Boolean
  },
  components: {
    CommentList: () => import('@/components/SurgeryCenter/General/CommentList'),
    TextArea: () => import('@/components/General/TextArea')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    user: getCurrentUser(),
    comments: [],
    comment: '',
    editIndex: null,
    isSaving: false
  }),
  methods: {
    async createComment(dataSave) {
      this.isSaving = true
      try {
        const { data } = await this.api.createSurgeryInformationComment(
          dataSave
        )
        return data
      } catch (error) {
        this.$toast.error('Erro ao criar comentário')
      } finally {
        this.isSaving = false
      }
    },
    async updateComment() {
      this.isSaving = true
      const dataSave = this.comments[this.editIndex]
      this.$set(dataSave, 'clinic_id', this.clinic.id)
      this.$set(dataSave, 'comment', this.comment)

      try {
        const { data } = await this.api.updateSurgeryInformationComment(
          dataSave.id,
          dataSave
        )
        return data
      } catch (error) {
        this.$toast.error('Erro ao atualizar comentário')
      } finally {
        this.comment = ''
        this.editIndex = null
        this.isSaving = false
      }
    },
    async addComment() {
      let data = {
        comment: this.comment,
        professional: this.user,
        updated_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
        clinic_id: this.clinic.id,
        surgery_information_id: this.surgeryInformationId
      }
      const response = await this.createComment(data)
      data = {
        ...data,
        ...response
      }
      this.comments.push(data)
      this.comment = ''

      this.updateComments()
    },
    async updateCommentArray() {
      let data = {
        ...this.comments[this.editIndex],
        comment: this.comment,
        updated_at: this.moment().format('YYYY-MM-DD HH:mm:ss'),
        clinic_id: this.clinic.id
      }
      const response = await this.createComment(data)
      data = {
        ...data,
        ...response
      }
      this.$set(this.comments, this.editIndex, data)
      this.editIndex = null
      this.comment = ''

      this.updateComments()
    },
    edit(index) {
      this.editIndex = index
      this.comment = this.comments[index]?.comment
    },
    updateComments(comments) {
      if (comments) {
        this.comments = comments
        this.$emit('update-comments', comments)
        return
      }
      this.$emit('update-comments', this.comments)
    }
  }
}
</script>
